
<embed SRC="assets/harley.mp3" autostart="true" hidden="true"/>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <button class="navbar-toggler" id="btnNavBar" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="eliteTextoMobile">
      <span class="titleElite">Elite da Estrada MC</span>
      <img class="imgtTitleBrasaoVazado" src="assets/eliteCaveira.png">
    </div>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" id="home" routerLink="/home" routerLinkActive="active" >Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/historia" routerLinkActive="active" >Historia</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/quemsomos" routerLinkActive="active" >Quem Somos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/inmemory" routerLinkActive="active" >In Memory</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/sede" routerLinkActive="active" >Nossa Sede</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/fotos" routerLinkActive="active">Fotos</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/agenda" routerLinkActive="active">Agenda</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/social" routerLinkActive="active">Social</a>
        </li> -->
        <li>
          <div class="iconsRedeSocial">
            <a href="https://www.facebook.com/ElitedaEstradaMC" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Siga-nos no Facebook"><i class="fab fa-facebook-square"></i></a>
            <a href="https://www.instagram.com/elitedaestradamc/" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Siga-nos no Instagram"><i class="fab fa-instagram"></i></a>
            <a href="https://www.youtube.com/channel/UC7ykv39hSlQhRy1cx_EidTQ" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Inscreva-se no nosso canal"><i class="fab fa-youtube"></i></a>
          </div>
        </li>
      </ul>
    </div>
    <div id="eliteTextoFullScreen">
      <span class="titleElite">Elite da Estrada MC</span>
      <img class="imgtTitleBrasaoVazado" src="assets/eliteCaveira.png">
    </div>
  </nav>
<div div class="bgElite">
</div>
  <router-outlet></router-outlet>
