
<div id="bgContent">
  <div class="container bgBody fadeIn">
    <div class="row justify-content-center titlebgBody">
      <div id="titleInMemory" class="col-xl-4 col-md-6 col-11">
        <h1>✠ In Memory ✟</h1>
      </div>
    </div>
    <div class="row justify-content-center divImgSed">
      <div id="txtInMemory" class="col-xl-4 col-md-6 col-12">
        <p class="has-dropcap">
          A morte é o destino de todos. Ninguém lhe pode escapar, e na
          hora do adeus final todos somos iguais. Ela causa profunda dor, desespero,
          lágrimas e eternas saudades.
        </p>
        <p>
          A morte provoca perdas irreparáveis e quebra corações, mas há algo que ela não
          pode, que é apagar das mentes e dos corações a lembrança e o amor
          daqueles que ela nos rouba.
        </p>
        <p>
          Assim, quem realmente é amado jamais morre, jamais desaparece completamente.
          Sua memória prevalece na nossa saudade e
          pode atravessar gerações mesmo depois de ter falecido.
        </p>
        <br>
        <h1>
          Obrigado Galego!
        </h1>
        <p>
          ✠ - 20/08/1983
          <br>
          ✟ - 21/10/2018
        </p>
      </div>
      <div id="imgSedeTitle" class="col-xl-6 col-md-6 col-12">
        <img src="assets/MemoryGalego.jpeg" class="imgMemory rounded img-fluid">
      </div>
    </div>
  </div>
</div>
