<div class="container fadeInHome">
  <div class="row justify-content-center">
    <div class="col-10 col-md-6 col-xl-6">
      <img class="imgBrasaoElite" src="assets/Brasão Vazado.png">
    </div>
  </div>
  <div class="row justify-content-center">
    <div>
      <h1 class="col-12 col-md-12 col-xl-12 fraseElite">
        Somos uma fraternidade cujo elo é inquebrável
      </h1>
    </div>
  </div>
</div>
