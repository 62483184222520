import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inmemory',
  templateUrl: './inmemory.component.html',
  styleUrls: ['./inmemory.component.css']
})
export class InmemoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
