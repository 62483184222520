<div id="bgContent">
  <div class="container bgBody fadeIn">
    <div class="row justify-content-center titlebgBody">
      <div class="col-xl-1 col-md-2 col-3">
        <h1>Sede</h1>
      </div>
    </div>
    <div class="row justify-content-center divImgSed">
      <div id="imgSedeTitle" class="col-xl-8 col-md-8 col-12">
        <img src="assets/Sede/Sede.jpg" class="imgSede rounded img-fluid">
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-md-3 col-12">
        <img src="assets/Sede/motoSede.jpeg" class="imgSede rounded">
      </div>
      <div class="col-xl-7 col-md-6 col-12 textoSede">
        <p class="has-dropcap"> {{textoImg1}}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7 col-md-6 col-12 textoSede">
        <p class="has-dropcap"> {{textoImg2}}
        </p>
      </div>
      <div class="col-xl-4 col-md-3 col-12">
        <img src="assets/Sede/crianca_sede.jpg" class="imgSede rounded">
      </div>
    </div>
    <div class="divLocalizacao">
      <h2>Como chegar</h2>
      <p class="textoEndereco">
        {{endereco}}
      </p>
      <div class="row divMaps">
        <div class="col-xl-12 col-md-4 col-12">
          <iframe class="mapsGoogle" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14631.717757587463!2d-46.461706627214795!3d-23.535040132289545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce676009d76553%3A0x5f08e0550a402c12!2sSede%20do%20Elite%20da%20Estrada%20MC!5e0!3m2!1spt-BR!2sbr!4v1607628701149!5m2!1spt-BR!2sbr" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

