import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sede',
  templateUrl: './sede.component.html',
  styleUrls: ['./sede.component.css']
})
export class SedeComponent implements OnInit {

  textoImg1 = "Nossa sede fica localizada na região de Itaquera, precisamente no Fenix Pub. " +
              "O Espaço é amplo para estacionar nossas motos e dos nossos convidados.";

  textoImg2 = "Traga sua família, seus filhos e venha curtir um dia conoso. Todos serão muito bem vindos!";

  endereco = "Rua: Padre Viegas de Menezes, 145 - Itaquera, São Paulo - SP, 08220-510";

  constructor() { }

  ngOnInit(): void {
  }

}
