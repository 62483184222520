import { InmemoryComponent } from './inmemory/inmemory.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HistoriaComponent } from './historia/historia.component';
import { SedeComponent } from './sede/sede.component';
import { QuemsomosComponent } from './quemsomos/quemsomos.component';
// import { SocialComponent } from './social/social.component';
// import { FotosComponent } from './fotos/fotos.component';
// import { AgendaComponent } from './agenda/agenda.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'historia', component: HistoriaComponent },
  { path: 'quemsomos', component: QuemsomosComponent },
  { path: 'sede', component: SedeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'inmemory', component: InmemoryComponent },
  // { path: 'social', component: SocialComponent },
  // { path: 'fotos', component: FotosComponent },
  // { path: 'agenda', component: AgendaComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
