<div id="bgContent">
  <div class="container bgBody fadeIn">
    <div class="row justify-content-center titlebgBody">
      <div class="col-xl-2 col-md-2 col-6">
        <h1>Historia</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-6 col-12">
        <img src="assets/Historia/fundadores.png" class="imgFundadores">
      </div>
      <div id="historiatxt" class="col-xl-6 col-md-6 col-12">
        <p class="has-dropcap">
          A primeira motocicleta surgiu em 1868 e desde então, ela vem despertando nos seres humanos o
          instinto de liberdade. Hoje chamados de motociclistas, essas pessoas que encontram na
          estrada o prazer de viajar sobre duas rodas conhecendo lugares, fazendo amizades,
          desbravando distâncias e conhecendo novas culturas.
        </p>
        <p class="has-dropcap">
          No Brasil a prática do motociclismo começou em 1927, quando surgiu a primeira
          associação desta categoria, o "Moto Club do Brasil" no estado do Rio de Janeiro.
          Atualmente os Moto Clubes se espalharam pelo país.
          E foi assim que em 15 de Março de 2016, um grupo de amigos apaixonados por moto se
          juntaram e fundaram o Elite da Estrada MC, com o intuito de rodar o país, interagir
          com outros motociclistas além de proporcionar ações sociais para diversas entidades.
        </p>
        <p>
          Os Moto Clubes vão muito além.
          <br>
          <b>"A liberdade é a nossa religião e a estrada o nosso templo".</b>
        </p>
      </div>
    </div>
  </div>
</div>
