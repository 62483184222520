<div id="bgContent">
  <div class="container bgBody fadeIn">
    <div class="row justify-content-center titlebgBody">
      <div class="col-xl-2 col-md-2 col-6">
        <h1>Diretoria</h1>
      </div>
    </div>
    <div id="integrantesMobile">
      <div class="row justify-content-center cardsIntegrantes">
        <div class="col-xl-4 col-md-3 col-12">
          <img id="imgPresidente" class="img-fluid rounded img-integrantes" src="assets/Integrantes/Estevao.jpg">
        </div>

        <div class="col-xl-4 col-md-3 col-12">
          <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Jordao.jpg">
        </div>

        <div class="col-xl-4 col-md-3 col-12">
          <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Esteves.jpg">
        </div>
      </div>
    </div>

    <div id="integrantesFullScreen">
      <div class="row justify-content-center cardsIntegrantes">
        <div class="col-xl-4 col-md-3 col-12">
          <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Esteves.jpg">
        </div>

        <div class="col-xl-4 col-md-3 col-12">
            <img id="imgPresidente" class="img-fluid rounded img-integrantes" src="assets/Integrantes/Estevao.jpg">
        </div>

        <div class="col-xl-4 col-md-3 col-12">
          <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Jordao.jpg">
        </div>
      </div>
    </div>

    <div class="row justify-content-center cardsIntegrantes">
      <div class="col-xl-4 col-md-3 col-12">
        <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Bilao.jpg">
      </div>

      <div class="col-xl-4 col-md-3 col-12">
        <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Ulisses.jpg">
      </div>

      <div class="col-xl-4 col-md-3 col-12">
        <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Celestino.jpg">
      </div>

      <div class="col-xl-4 col-md-3 col-12">
        <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Roni.jpg">
      </div>

      <div class="col-xl-4 col-md-3 col-12">
        <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Maciel_Peixe.jpg">
      </div>

      <div class="col-xl-4 col-md-3 col-12">
        <img class="img-fluid rounded img-integrantes" src="assets/Integrantes/Giraya.jpg">
      </div>
    </div>
  </div>
</div>
