import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HistoriaComponent } from './historia/historia.component';
import { FotosComponent } from './fotos/fotos.component';
import { AgendaComponent } from './agenda/agenda.component';
import { SedeComponent } from './sede/sede.component';
import { QuemsomosComponent } from './quemsomos/quemsomos.component';
import { HomeComponent } from './home/home.component';
import { SocialComponent } from './social/social.component';
import { InmemoryComponent } from './inmemory/inmemory.component';

@NgModule({
  declarations: [
    AppComponent,
    HistoriaComponent,
    FotosComponent,
    AgendaComponent,
    SedeComponent,
    QuemsomosComponent,
    HomeComponent,
    SocialComponent,
    InmemoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
